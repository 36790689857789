/*
    スタンプラリーをゲットする方法・商品の応募方法
*/
import minakuru from "../../../assets/minakuruA3.jpg";

export const ParticipationMethod = (props) => {

    return(
        <div id="home-participationmethod" className="w-screen bg-emerald-200 bg-opacity-80">
            {/*ヘッダー*/}
            <div id="home-participationmethod-header">
                <p className="h-24 text-center text-2xl text-white p-3 pt-8 font-semibold bg-emerald-400">応募方法</p>
            </div>
            {/*内容*/}
            <img src={minakuru} alt={"みなくる商店会スタンプラリー"}/>
            <div className="text-center text-neutral-600 py-4 px-4">
                <h1 className="text-3xl py-2">1</h1>
                <p>各店舗に行き、QRコードをカメラで読み取る</p>
            </div>
            <div className="text-center text-neutral-600 py-4 px-4">
                <h1 className="text-3xl py-2">2</h1>
                <p>スタンプが4つ貯まったら、右上のスタンプボタンを押してください。ボタンを押したら、スタンプカードの下のボタンをクリック！</p>
            </div>
            <div className="text-center text-neutral-600 py-4 px-4">
                <h1 className="text-3xl py-2">3</h1>
                <p>応募フォームに必要事項を記入し、プレゼント応募に参加！</p>
            </div>
        </div>
    )
}