/*
    エントリーフォームのコンポーネント
*/
import TopIcon from "../../../assets/top-img.png";
import { InputAddress, InputMailaddress, InputName, InputPostCode, InputTel } from "../../parts";
import { Link } from "react-router-dom";
import { useEffect } from "react";

export const EntryForm = (props) => {
    const onLastNameChange = props.onLastNameChange;
    const onFirstNameChange = props.onFirstNameChange;
    const onTelChange = props.onTelChange;
    const onMailaddressChange = props.onMailaddressChange;
    const onPostCodeChange = props.onPostCodeChange;
    const onAddressChange = props.onAddressChange;
    const onGenderChange = props.onGenderChange;
    const searchAddress = props.searchAddress;
    const firstName = props.firstName;
    const lastName = props.lastName;
    const tel = props.tel;
    const mailaddress = props.mailaddress;
    const postCode = props.postCode;
    const address = props.address;
    const gender = props.gender;
    const onClickButton = props.onClickButton;
    const error = props.error;
    const genderArray = props.genderArray;

    return(
        <div id="entry-entryfield" className="relative px-8 py-12">
            {/*ヘッダー*/}
            <div id="entry-entryfield-header" className="py-2">
                <div className="flex justify-center items-center pb-4">
                    <img src={TopIcon} alt={"icon"} className="w-1/4 h-1/4"></img>
                </div>
                <h1 className="text-3xl font-bold text-center">応募フォーム</h1>
            </div>
            {/*エラーメッセージ*/}
            <div id="entry-entryfield-error" className="py-4">
            {
                error.isError && <p className="text-sm text-red-500 px-2">{error.errorMsg}</p>
            }
            </div>
            {/*名前入力フォーム*/}
            <div id="entry-entryfield-name" className="py-2">
                <p className="text-left text-sm font-bold">名前</p>
                <div className="grid grid-cols-2 gap-4">
                    <InputName holder={"姓"} value={lastName} onChangeText={onLastNameChange} />
                    <InputName holder={"名"} value={firstName} onChangeText={onFirstNameChange} />
                </div>
            </div>
            {/*フリガナ入力フォーム*/}
            {/*電話番号入力フォーム*/}
            <div id="entry-entryfield-tel" className="py-2">
                <p className="text-left text-sm font-bold">電話番号</p>
                <p className="text-left text-xs font-bold py-2">※半角英数字のみ、「-（ハイフン）」なしで入力してください</p>
                <InputTel value={tel} onChangeText={onTelChange} />
            </div>
            {/*メールアドレス入力フォーム*/}
            <div id="entry-entryfield-mailaddress" className="py-2">
                <p className="text-left text-sm font-bold">メールアドレス</p>
                <InputMailaddress value={mailaddress} onChangeText={onMailaddressChange} />
            </div>
            {/*郵便番号入力フォーム*/}
            <div id="entry-entryfield-postcode" className="py-2">
                <p className="text-left text-sm font-bold">郵便番号</p>
                <p className="text-left text-xs font-bold py-2">※半角英数字のみ、「-（ハイフン）」なしで入力してください</p>
                <div className="grid grid-cols-2 gap-4">
                    <InputPostCode onChangeText={onPostCodeChange} value={postCode}/>
                    <button className="p-2 border-2 w-2/3 font-bold" onClick={searchAddress}>住所検索</button>
                </div>
            </div>
            {/*住所入力フォーム*/}
            <div id="entry-entryfield-address" className="py-2">
                <p className="text-left text-sm font-bold">住所</p>
                <InputAddress onChangeText={onAddressChange} address={address}/>
            </div>
            {/*完了ボタン*/}
            <div className="flex justify-center items-center py-8">
                <button className="px-2 py-4 w-1/2 bg-cyan-400 text-white font-semibold rounded" onClick={onClickButton}>内容確認</button>
            </div>
            {/*Homeページリンク*/}
            <Link to={"/home"}>
                <p className="absolute top-2 left-2"> ホームへ戻る</p>
            </Link>
        </div>
    )
}