/*
    賞品を応募する際の入力フォームするページ
*/
import { EntryView } from "../views"

export const Entry = () => {
    return(
        <>
            <EntryView />
        </>
    )
}