/*
    Topページに強制的にリンクを飛ばす
*/
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const TopLink = () => {
    const navigate = useNavigate();

    useEffect(() => {
        //  レンダリング時にTopページにリンクを飛ばす
        navigate("/");
    },[]);

    return(
        <></>
    )
}