/*
    Webサイト起動時に表示される画面
*/
import TopIcon from "../../../assets/top-img.png";

export const Main = (props) => {
    const startDate = props.startDate;  //  スタンプラリー開始日
    const endDate = props.endDate;      //  スタンプラリー終了日

    return(
        <div id="article-main" className="w-screen">
            {/*メイン画像*/}
            <div className="h-screen w-screen">
                <div className="h-full w-full flex justify-center items-center">
                    <div className="">
                        <div className="flex justify-center items-center">
                            <img src={TopIcon} alt="topicon" className="h-1/2 w-1/2"></img>
                        </div>
                        <div id="article-main-during" className="text-center px-8 py-8 font-semibold">
                            <p className="py-2 text-2xl">開催期間</p>
                            <p className="text-2xl">{startDate.year}.{startDate.month}.{startDate.date} ー {endDate.year}.{endDate.month}.{endDate.date}</p>
                        </div>
                    </div>                                
                </div>         
            </div>
        </div>
    )
}