/*
    Homeページのフッター(スタンプをゲットするために押すボタン)
*/

export const HomeFooter = (props) => {
    const clickQRButton = props.clickQRButton;  //  QRボタンをクリックしたときの処理

    return(
        <footer id="home-homefooter" className="flex justify-center items-center">
            <button className="fixed w-5/6 h-homeFooter rounded-full p-4 bg-gradient-to-r from-cyan-500 to-blue-500 flex justify-center items-center bottom-4" onClick={clickQRButton}>
                <p className="text-center text-white text-xl font-semibold">スタンプGET!</p>
            </button>
        </footer>
    )
}