/*
    新規登録をするページ
    役割
    １・ユーザーの新規登録
    ２・ユーザー登録が完了している際、ログイン画面にリンクを飛ばす
*/

import { SignupView } from "../views"

export const Signup = () => {
    return(
        <>
            <SignupView />
        </>
    )
}