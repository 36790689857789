/*
    サインアップに必要なメールアドレスとパスワード、確認用パスワードを入力するフォーム
*/
import { useState } from "react";
import { InputMailaddress, InputPassword, SignupButton } from "../../parts"
import { auth, db } from "../../firebase";
import { Link, useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword, deleteUser, sendEmailVerification } from "firebase/auth";
import { doc, serverTimestamp, setDoc } from "firebase/firestore";
import TopIcon from "../../../assets/top-img.png";

export const SignupField = () => {
    const navigate = useNavigate(); //  リンクを飛ばすためのインスタンス

    const [mailaddress, setMailaddress] = useState(""); //  メールアドレス
    const [password, setPassword] = useState("");       //  パスワード
    const [conPassword, setConPassword] = useState(""); //  確認用パスワード

    //  エラーに関するオブジェクト
    const [error, setError] = useState({
        isError: false,     //  入力された内容にエラーがあるかどうか
        errorMsg: ""        //  エラー内容（表示させるエラーの文章）
    });

    //  入力フォームの値をmailaddressに格納
    const onMailaddressChange = (e) => {
        setMailaddress(e.target.value);
        console.log(mailaddress);
    };

    //  入力フォームの値をpasswordに格納
    const onPasswordChange = (e) => {
        setPassword(e.target.value);
        console.log(password);
    };

    //  入力フォームの値をconPasswordに格納
    const onConPasswordChange = (e) => {
        setConPassword(e.target.value);
        console.log(conPassword);
    }

    //  バリデーション機能
    const validation = (errorCode) => {
        console.log("validation start!");
        var errorMsg = "";  //  エラーメッセージ

        console.log(errorCode);

        //  記入漏れ
        if(mailaddress === "" || password === "" || conPassword === ""){
            errorMsg = "メールアドレスまたはパスワードが入力されていません";
            showErrorMsg(errorMsg);
            return;
        }

        //  パスワードと確認用パスワードの不一致
        if(password !== conPassword){
            errorMsg = "パスワードと確認用パスワードが一致しません";
            showErrorMsg(errorMsg);
            return;
        }

        //  firebase authenticationを利用した認証       
        switch(errorCode){
            case 'auth/invalid-email':
                errorMsg = "メールアドレスの形式が正しくありません";
                break;
            case 'auth/email-already-in-use':
                errorMsg = "このメールアドレスは使用されています";
                break;
            case 'auth/weak-password':
                errorMsg = "パスワードは6文字以上にしてください";
                break;
            default:
                errorMsg = 'エラーが発生しました。しばらく時間をおいてお試しください';
        }
        showErrorMsg(errorMsg);
    };

    //  エラーメッセージを表示する関数
    const showErrorMsg = (errorMsg) => {
        //  エラーメッセージを格納
        setError({
            isError: true,
            errorMsg: errorMsg
        })
    }

    //  サインアップのときに実行される関数
    const signup = () => {
        // firebase Authenticationにユーザー登録(アカウント作成)
        createUserWithEmailAndPassword(auth, mailaddress, password)
            .then((userCredential) => {
                //  ユーザー情報をサーバに送信・登録
                sendUserInfo(userCredential.user.uid)
                    .then(
                        //  非同期処理が成功した時、認証メールを送信する
                        sendEmailVerification(auth.currentUser)
                            .then(() => {
                                //  メール送信完了後の処理
                                console.log("send verified Email!");
                                navigate('/register');
                            })
                    )
                    .catch(() => {
                        //  ユーザ登録に失敗した場合にauthenticationのユーザを消去する
                        deleteUser(userCredential.user.uid);
                    })
            })
            .catch((error) => {
                //  パスワード認証に失敗した時、バリデーションをしてエラーを表示
                console.log("error Discover!");
                validation(error.code);
            })
    };

    //  非同期処理でユーザー情報を登録
    const sendUserInfo = async(userID) => {
        //  ユーザー基本情報をサーバに登録
        const userRef = doc(db, "users", userID);

        console.log("cerate UserInfo!");

        setDoc(userRef, {
            state: "customer",
            createAt: serverTimestamp(),
            isGetGoods: false
        })
        //  ユーザースタンプ情報をサーバに登録
        const stampRef = doc(db, "stamps", userID);
        setDoc(stampRef, {
            stamp: []
        })
    }

    return(
        <div id="signup-signupfield" className="border-2 border-gray-200 px-8 py-12">
            {/*ヘッダー*/}
            <div id="login-loginfield-header" className="py-2">
                <div className="flex justify-center items-center pb-4">
                    <img src={TopIcon} alt={"icon"} className="w-1/4 h-1/4"></img>
                </div>
                <div className="text-3xl font-bold text-center">新規登録</div>
            </div>
            {/*エラーメッセージ*/}
            <div id="signup-signupfield-error" className="py-4">
            {
                (error.isError && 
                    <p className="text-sm text-red-500 px-2">{error.errorMsg}</p>
                )
            }
            </div>
            {/*メールアドレス入力フォーム*/}
            <div id="signup-signupfield-mailaddress" className="py-2">
                <p>メールアドレス</p>
                <InputMailaddress value={mailaddress} onChangeText={onMailaddressChange} />
            </div>
            {/*パスワード入力フォーム*/}
            <div id="signup-signupfield-password" >
                <p>パスワード</p>
                <InputPassword onChangeText={onPasswordChange}  className="py-2" />
            </div>
            {/*確認用パスワード入力フォーム*/}
            <div id="signup-signupfield-conpassword" className="py-2">
                <p>確認用パスワード</p>
                <InputPassword onChangeText={onConPasswordChange} />
            </div>
            {/*ログインボタン*/}
            <div id="signup-signupfield-signupbutton"  className="py-4 flex justify-center items-center">
                <SignupButton signup={signup}/>
            </div>
            {/*リンクフォーム*/}
            <div id="signup-signupfield-linkform" className="pt-2">
            <p className="text-sm text-center">会員登録が完了している方はこちら</p>
                <Link to={"/login"}>
                    <p className="text-sm text-cyan-300 underline text-center">ログイン</p>
                </Link>
            </div>
        </div>
    )
}