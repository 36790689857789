/*
    エントリーフォームに入力された内容の確認画面を表示
*/
import TopIcon from "../../../assets/top-img.png";
import { Link } from "react-router-dom";

export const ConfirmEntryForm = (props) => {
    //  入力内容
    const lastName = props.lastName;            //  姓
    const firstName = props.firstName;          //  名
    const mailaddress = props.mailaddress;      //  メールアドレス
    const tel = props.tel;                      //  電話番号
    const postCode = props.postCode;            //  郵便番号
    const address = props.address;              //  住所

    const onClickReturn = props.onClickReturn;
    const onClickEntry = props.onClickEntry;

    return(
        <div id="entry-confirmentoryform" className="relative px-8 py-12">
            {/*ヘッダー*/}
            <div id="entry-confirmentryfield-header" className="py-2">
                <div className="flex justify-center items-center pb-4">
                    <img src={TopIcon} alt={"icon"} className="w-1/4 h-1/4"></img>
                </div>
                <h1 className="text-3xl font-bold text-center">入力内容の確認</h1>
            </div>
            {/*入力内容の確認*/}
            <div className="py-2">
                <p className="text-left text-sm font-bold text-gray-500">名前</p>
                <p className="text-left text-lg font-bold px-4">{lastName} {firstName}</p>
            </div>
            <div className="py-2">
                <p className="text-left text-sm font-bold text-gray-500">メールアドレス</p>
                <p className="text-left text-lg font-bold px-4">{mailaddress}</p>
            </div>
            <div className="py-2">
                <p className="text-left text-sm font-bold text-gray-500">電話番号</p>
                <p className="text-left text-lg font-bold px-4">{tel}</p>
            </div>
            <div className="py-2">
                <p className="text-left text-sm font-bold text-gray-500">郵便番号</p>
                <p className="text-left text-lg font-bold px-4">〒{postCode}</p>
            </div>
            <div className="py-2">
                <p className="text-left text-sm font-bold text-gray-500">住所</p>
                <p className="text-left text-lg font-bold px-4">{address}</p>
            </div>
            {/*ボタン*/}
            <div className="py-8 grid grid-cols-2 gap-4">
                <button className="px-2 py-4 w-full bg-cyan-400 text-white font-semibold rounded" onClick={onClickReturn}>
                    応募フォームへ戻る
                </button>
                <button className="px-2 py-4 w-full bg-cyan-400 text-white font-semibold rounded" onClick={onClickEntry}>
                    応募する
                </button>
            </div>
            {/*Homeページリンク*/}
            <Link to={"/home"}>
                <p className="absolute top-2 left-2"> ホームへ戻る</p>
            </Link>
        </div>
    )
}