/*
    仮登録を済ませたユーザーに本登録を促す
    役割
    １・本登録用メールの送信ボタンを表示
    ２・ログインページへのリンク
*/
import { DefinitiveRegistrationView } from "../views"

export const DefinitiveRegistration = () => {
    return(
        <>
            <DefinitiveRegistrationView />
        </>
    )
}