/*
    スタンプカードのグリッドに関するコンポーネント
*/
import minakuruIcon from "../../../assets/minakuru.jpg";
import stampIcon from "../../../assets/stampGrid.png";
import { useEffect, useState } from "react";
import { getDownloadURL } from "firebase/storage";
import { storage } from "../../firebase";
import { ref } from "firebase/storage";

export const StampCardGrid = (props) => {
    const gridNum = props.gridNum;  //  グリッド番号
    const stamp = props.stamp;      //  ユーザーのスタンプデータ

    const [imgURL, setImgURL] = useState();

    const urlBase = "gs://solid-clarity-274514.appspot.com/stores/"; //  データ取得用URLのベース
    const urlEnd = ".jpg";                          //  データ取得用URLの末尾
    const generalurl = "gs://solid-clarity-274514.appspot.com/stores/base.jpg";

    useEffect(() => {
        if(gridNum < stamp.length){            
            getDownloadURL(ref(storage, urlBase + stamp[gridNum].img + urlEnd))
                .then((url) => {
                    //  URLを変数に格納
                    setImgURL(url);
                    console.log(stamp[gridNum].store);
                })
                .catch(() => {                    
                    getDownloadURL(ref(storage, generalurl))
                        .then((url) => {
                            setImgURL(url);
                        })
                })    
        }
    },[])

    return(
        <>
            <div className="border-gray-500 border-2 border-l-0 w-auto h-stampHeight">
                <div className="flex justify-center items-center">
                    {
                        (stamp.length > gridNum)?(
                            <img src={imgURL} alt="stampCardGrid" className="w-stampWidth h-stampHeight"></img>                            
                        ):(
                            <img src={stampIcon} alt="Not Stamp" className="w-stampWidth h-stampHeight"></img>
                        )
                    }
                </div>
            </div>
        </>
    )
}