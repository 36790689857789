/*
    DefinitiveRegistrationページに強制的にリンクを飛ばす
*/
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const RegistrationLink = () => {
    const navigate = useNavigate();

    useEffect(() => {
        //  レンダリング時にTopページにリンクを飛ばす
        navigate("/register");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return(
        <></>
    )
}