/*
    画面遷移時にページ上部にスクロールするシステム
*/
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const ScrollTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0,0);
    },[pathname]);

    return null;
}