/*
    Webサイトをクリックした際に表示されるページ
    役割
    １・デジタルスタンプラリーの開催概要・参加方法などを記載
    ２・ログイン・新規登録を促す
*/

import { TopView } from "../views"

export const Top = () => {
    return(
        <>
            <TopView />
        </>
    )
}