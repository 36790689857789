/*
    名前の入力フォーム
*/
export const InputName = (props) => {
    const onChangeText = props.onChangeText;
    const holder = props.holder;    //  placrholderの文

    return(
        <input type="text" value={props.value} placeholder={holder} onChange={onChangeText} className="bg-gray-200 appearance-none border-2 border-gray-400 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-cyan-300 focus:border-2"></input>
    )
}