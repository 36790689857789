/*
    Homeページの最初に見えるメイン部分
*/
import MainImage from "../../../assets/memuro-home.png";

export const HomeMain = () => {
    return(
        <div className="w-screen h-auto">
            {/*フライヤー*/}
            <img src={MainImage} alt={"mainimage"} className="w-screen animate-slideIn"></img>
        </div>
    )
}