/*
    Homeページのヘッダー
*/
import { signOut } from "firebase/auth";
import { SignoutButton } from "../../parts";
import { auth } from "../../firebase";
import { Link } from "react-scroll";
import TopIcon from "../../../assets/top-img.png";
import StampIcon from "../../../assets/homeicon/stamp-48.png";

export const HomeHeader = (props) => {
    const openStampModal = props.openStampModal;    //  スタンプモーダルを開く関数

    return(
        <div id="home-homeheader" className="fixed z-10 w-screen top-0 left-0 bg-white h-homeHeader border-b-2 border-gray-200 grid grid-cols-6">
            <div className="flex justify-center items-center w-homeHeader h-homeHeader">
                <img src={TopIcon} alt={"icon"} className="h-3/4 w-3/4"></img>
            </div>
            <SignoutButton signout={() => signOut(auth)} />
            <div></div>
            <div></div>
            <div></div>
            <button className="flex justify-center items-center border-l-2 border-gray-200 w-homeHeader h-homeHeader" onClick={() => openStampModal()}>
                <img src={StampIcon} alt="stampIcon" className="h-fit w-fit"></img>
            </button>            
        </div>
    )
}