/*
    店情報を記載したリスト
*/
import { useState, useEffect } from "react";
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../../../firebase";

export const StoreInfo = (props) => {
    const data = props.data;    //  リストに表示するデータ
    const stamp = props.stamp;  //  ユーザのスタンプデータ
    const clickStoreInfo = props.clickStoreInfo;  //  StoreInfo（店舗情報）をクリックしたときの処理

    const urlBase = "gs://solid-clarity-274514.appspot.com/stores/"; //  データ取得用URLのベース
    const urlEnd = ".jpg";                          //  データ取得用URLの末尾
    const generalurl = "gs://solid-clarity-274514.appspot.com/stores/base.jpg";

    const [stampFlag, setStampFlag] = useState(false);  //  リスト店舗のスタンプが獲得済みかどうか
    
    const [imgURL, setImgURL] = useState("");   //  リスト画像のURL

    useEffect(() => {
        getImage()
            .then(
                //  スタンプが取得済みかどうか。取得済みの場合stampFlagをtrueにする
                stamp.map((item) => {
                    return(
                        (item === data.id) && setStampFlag(true)
                    )                    
                })
            )
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    //  表示する画像のURLを取得
    const getImage = async() => {
        if(data.img === ""){
            //画像が登録されていない場合、汎用画像のURLをセットする
            data.img = "minakuru";
        }

        const gsReference = ref(storage, urlBase + data.img + urlEnd);
        const generalReference = ref(storage, generalurl);

        //  指定したURLの画像をストレージから取得する
        getDownloadURL(gsReference)
            .then((url) => {
                //  URLを変数に格納
                setImgURL(url);
            })
            .catch(() => {
                getDownloadURL(generalReference)
                    .then((url) => {
                        setImgURL(url);
                    })
            })     
    };

    //  モーダルウィンドウの開閉を管理
    const clickModal = () => {
        //  StoreModalを表示し、データを送る
        clickStoreInfo(data, imgURL, stampFlag);
    }

    return(
        <>
            <div className="w-auto relative flex justify-center bg-gray-100 border-2 border-blackGreen" onClick={() => clickModal()}>
                <img src={imgURL} alt={data.store}></img>
                <p className="text-xs bg-black absolute bottom-0 text-white w-full bg-opacity-70 py-1">{data.store}</p>
            </div>
        </>
        
    )
}