export const Infomation = () => {
    return(
        <div id="home-participationmethod" className="w-screen h-auto bg-yellow-200 bg-opacity-80">
            {/*ヘッダー*/}
            <div id="home-goods-header">
                <p className="h-24 text-center text-2xl text-white p-3 pt-8 font-semibold bg-yellow-400">お問い合わせ</p>
            </div>
            {/*ボディ*/}            
            <h1 className="font-semibold text-xl text-center py-4 px-8">
                問題がおこった場合は以下の電話番号までご連絡お願いします。
            </h1>
            <div className="font-semibold text-center py-4">
                <p className="text-xl py-2">三浦商店</p>
                <a href="tel:08060926587" className="text-2xl">080-6092-6587</a>
            </div>
        </div>
    )
}