import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    /* 作成したfirebaseConfigキーをコピー＆ペースト */
    apiKey: "AIzaSyABLkrFgFcOXQCD4tjph9-ij1gYONDML4M",
    authDomain: "solid-clarity-274514.firebaseapp.com",
    projectId: "solid-clarity-274514",
    storageBucket: "solid-clarity-274514.appspot.com",
    messagingSenderId: "273355112805",
    appId: "1:273355112805:web:7a2b137401be05b82c618c"
}

const secondaryAppConfig = {
    apiKey: "AIzaSyADmY3IrN556114Mc_HArnw89xbGeGXPQI",
    authDomain: "minakuru-7f1b8.firebaseapp.com",
    projectId: "minakuru-7f1b8",
    storageBucket: "minakuru-7f1b8.appspot.com",
    messagingSenderId: "283943221543",
    appId: "1:283943221543:web:a0dd4dac66918aa27a5102"
}

// firebaseの初期化
const app = initializeApp(firebaseConfig);
const secondaryApp = initializeApp(secondaryAppConfig, "secondary");

const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

const entryDB = getFirestore(secondaryApp);

export { auth, db, storage, entryDB};