/*
    スタンプカード画面
*/
import { StampCardGrid } from "../../../../parts";

export const StampCard = (props) => {
    const stamp = props.stamp;  //  ユーザーのスタンプデータ
    const stampAchieved = props.stampAchieved;  //  スタンプカードの目標数

    return(
        <>
            <div className="font-bold px-4 pt-8">
                <h1 className="text-xl text-center">スタンプを{stamp.length}つゲットしました!</h1>
                <div className="py-4 text-base text-left">
                    <p>スタンプをあと{stampAchieved - stamp.length}個ゲットすると応募することができます。</p>
                </div>
                <div className="py-4">
                    <div className="flex justify-center grid grid-cols-4 gap-0 border-l-2 border-gray-500">
                        {/*ここの処理をきれいに実装したい*/}
                        <StampCardGrid gridNum={0} stamp={stamp}/>
                        <StampCardGrid gridNum={1} stamp={stamp}/>
                        <StampCardGrid gridNum={2} stamp={stamp}/>
                        <StampCardGrid gridNum={3} stamp={stamp}/>
                    </div>
                </div>
            </div>
        </>
    )
}