/*
    スタンプラリーサイトのメイン部分
    役割
    １・ストア情報を一覧表示　また　詳細情報の表示
    ２・QRスタンプの獲得
*/
import { HomeView } from "../views"

export const Home = () => {
    return(
        <HomeView />
    )
}