/*
    Homeビューの構成
    １・ヘッダー（参加方法リンク、スタンプカード、ログアウトボタン）
    ２・店舗情報（店舗画像、名前）
        -> クリックするとモーダルウィンドウが開き以下の情報を表示
            1   紹介文
            2   ユーザーが訪れたかどうか
            3   住所（クリックするとGoogleMapにリンクを飛ばす）
            4   電話番号（クリックすると電話をかける）
            5   営業時間
            6   定休日
    ３・参加方法の解説
    ４・景品の解説
    ５・QRコード読み取り用のモーダルウィンドウ
*/
import { useState, useEffect } from "react";
import { auth, db } from "../firebase";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { Goods, HomeFooter, HomeHeader, HomeMain, Loading, ParticipationMethod, RegistrationLink, StoreTable, TopLink } from "../templates";
import { QRModal, StampModal, StoreModal } from "../organisms";
import { animateScroll,  } from "react-scroll";


export const Home = () => {
    const [user, loading] = useAuthState(auth);  //  firebaseのログイン状態

    //  ユーザに関する情報
    const [stamp, setStamp] = useState([]); //  ユーザのスタンプデータ
    
    const [storeData, setStoreData] = useState([]); //  店舗情報

    const [style, setStyle] = useState({}); //  モーダルウィンドウを非表示した時のcssStyle

    //  StoreModalに関する変数
    const [isStoreModal, setIsStoreModal] = useState(false);    //  StoreModalを表示するかどうか
    const [storeModalData, setStoreModalData] = useState([]);   //  StoreModalに表示するデータ
    const [imgURL, setImgURL] = useState("");                   //  StoreModalに表示する画像のURL

    //  QRModalに関する変数
    const [isQRModal, setIsQRModal] = useState(false);          //  QRModalを表示するかどうか

    //  StampModalに関する変数
    const [isStampModal, setIsStampModal] = useState(false);    //  StampModalを表示するかどうか
    const [stampFlag, setStampFlag] = useState(false);          //  StampModalに表示する店舗がすでにスタンプ獲得済みかどうか

    //
    const [modalScroll, setModalScroll] = useState(0);  //  モーダルを開いたときのスクロール値を取得

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[user]);

    //  非同期処理で各種データを受け取る
    const getData = async() => {
        //   ログイン状態&本登録が完了している場合データを取得
        if(user && auth.currentUser.emailVerified){
            //  店舗情報を取得する
            getDocs(collection(db, "stores"))
                .then(snap => {
                    snap.docs.map((doc) => {
                        return(
                            setStoreData((prevArray) => [...prevArray, {
                                id: doc.id,
                                address: doc.data().address,
                                store: doc.data().store,
                                tel: doc.data().tel,
                                location: doc.data().location,
                                operatingHours: doc.data().operatingHours,
                                holiday: doc.data().holiday,
                                img: doc.data().img,
                            }])
                        )
                    })
                });
            
            //  ユーザのスタンプ情報を取得
            getDoc(doc(db, "stamps", auth.currentUser.uid))
                .then((docsnap) => {
                    //  docsnapにデータが存在していたらデータを格納
                    setStamp(docsnap.data().stamp);
                });
        }
    }

    //  スタンプデータを更新する
    const updateStamp = () => {
        //  ユーザのスタンプ情報を取得
        getDoc(doc(db, "stamps", auth.currentUser.uid))
            .then((docsnap) => {
                setStamp(docsnap.data().stamp);       
            });
    }

    //  モーダルが表示・非表示になっているときのStoreTableのcssを変更する処理
    const changeStyle = (flag) => {
        if(flag){
            setStyle({
                position: "fixed",
            });
        }else if(!flag){
            setStyle({});
        } 
    }

    //  指定した位置にスクロール
    const scrollTo = () => {
        animateScroll.scrollTo(modalScroll, {
            duration: 10
        })
    };

/*************************************************************************************************/

    /* StoreModal*/

    //  StoreInfo(店舗情報)をクリックしたときの処理
    const openStoreModal = (data, img, flag) => {
        //  StoreModalを表示する
        setIsStoreModal(true);
        //  表示するデータの格納
        getStoreModalData(data, img, flag);
        //  モーダルウィンドウ表示時の背景スクロールを動かなくさせる
        changeStyle(true);
        //  スクロール位置を取得する
        setModalScroll(window.scrollY);
    };

    //  StoreModalに表示するデータを取得
    const getStoreModalData = (data, img, flag) => {
        //  取得したデータを格納
        setStoreModalData(data);
        setImgURL(img);
        setStampFlag(flag);
    };

    //  StoreModalを閉じるときの処理
    const closeStoreModal = () => {
        //  StoreModalの非表示にする
        setIsStoreModal(false);
        //  モーダルウィンドウ表示時の背景スクロールを動かなくさせる
        changeStyle(false);
        //  StampFlagをfalseにする
        setStampFlag(false);
        //  指定した位置にスクロールする
        scrollTo();
        setModalScroll(0);
    };

/************************************************************************************************/

    /*QRModal*/

    //  QRボタンをクリックしたときの処理
    const openQRModal = () => {
        //  QRModalを表示する
        setIsQRModal(true);
        //  モーダルウィンドウ表示時の背景スクロールを動かなくさせる
        changeStyle(true);
        //  スクロール位置を取得する
        setModalScroll(window.scrollY);
    };

    //  QRModalを閉じるときの処理
    const closeQRModal = () => {
        //  QRModalを非表示にする
        setIsQRModal(false);
        //  モーダルウィンドウ表示時の背景スクロールを動かなくさせる
        changeStyle(false);
        //  指定した位置にスクロールする
        scrollTo();
        setModalScroll(0);
    }

/************************************************************************************************/

    /*StampModal*/

    //  StampModalボタンをクリックしたときの処理
    const openStampModal = () => {
        //  StampModalを表示する
        setIsStampModal(true);
        //  モーダルウィンドウ表示時の背景スクロールを動かなくさせる
        changeStyle(true);
        //  スクロール位置を取得する
        setModalScroll(window.scrollY);
    };

    //  StampModalを閉じるときの処理
    const closeStampModal = () => {
        //  QRModalを非表示にする
        setIsStampModal(false);
        //  モーダルウィンドウ表示時の背景スクロールを動かなくさせる
        changeStyle(false);
        //  指定した位置にスクロールする
        scrollTo();
        setModalScroll(0);
    }

/************************************************************************************************/

    return(
        <>
        {
            loading ? (
                <Loading />
            ):(
                user ? (
                    //  ログイン時の処理
                    auth.currentUser.emailVerified ? (
                        //  本登録が完了している場合の処理
                        <div id="home" className="w-screen">
                            <HomeHeader openStampModal={openStampModal}/>
                            <div className="h-homeHeader"></div>
                            <div style={style}>
                                <HomeMain />
                                <StoreTable storeData={storeData} stamp={stamp} clickStoreInfo={openStoreModal}/>
                                <div id="participate">
                                    <ParticipationMethod />
                                </div>
                            </div>                            
                            <div className="h-homeFooter-margin"></div>
                            <HomeFooter  clickQRButton={openQRModal}/>
                            {/*モーダルを一つのコンポーネント化したい*/}
                            {/*店舗詳細情報*/}
                            {
                                isStoreModal && <StoreModal data={storeModalData} img={imgURL} closeModal={closeStoreModal} stampFlag={stampFlag}/>
                            }
                            {/*QRスタンプゲット*/}
                            {
                                isQRModal && <QRModal closeModal={closeQRModal} stamp={stamp} storeData={storeData} updateStamp={updateStamp}/>
                            }
                            {/*スタンプカード*/}
                            {
                                isStampModal && <StampModal closeModal={closeStampModal} stamp={stamp} />
                            }
                        </div>
                    ):(
                        //  本登録が完了していない場合の処理
                        <>
                            <RegistrationLink />
                        </>
                    )
                ):(
                    //  ログアウト時の処理
                    <TopLink />
                )
            )
        }
        </>
    )
}