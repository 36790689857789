/*
    店舗情報を表形式で表示する
*/
import { useEffect, useState } from "react";
import { StoreInfo } from "../../organisms";

export const StoreTable = (props) => {
    const storeData = props.storeData;  //  店舗情報
    const stamp = props.stamp;          //  ユーザのスタンプデータ
    const clickStoreInfo = props.clickStoreInfo;    //  StoreInfo（店舗情報）をクリックした時の処理

    const [storeList, setStoreList] = useState([]);     //  StoreTableに表示する店舗情報のリスト

    useEffect(() => {
        //  StoreDataを最初に読み込むときの処理(アプリ起動時StoreTableの描画)
        setData(storeData);
    },[storeData]);

    //  配列をstoreListに格納する
    const setData = async(data) => {        
        setStoreList([]);   //  storeListを初期化する        
        setStoreList(data); //  引数の配列をstoreListに格納する
    }

    return(
        <div id="home-storetable" className="w-screen bg-red-200 bg-opacity-80">
            {/*ヘッダー*/}
            <div id="home-storeTable-header">
                <p className="h-24 text-center text-2xl text-white px-4 py-8 font-semibold bg-red-400">加盟店舗</p>
            </div>
            {/*ボディ*/}
            {/*ストアリスト*/}
            <div id="home-storetable-body" className="h-auto px-8 py-4">
                <div className="grid grid-cols-2 gap-4">
                {
                    (storeList.length !== 0 ? (
                        //  該当する店舗が存在するときの処理
                        storeList.map((item,index) => {
                            return(
                                <StoreInfo key={index} data={item} stamp={stamp} clickStoreInfo={clickStoreInfo}/>
                            )
                        })
                    ):(
                        //  該当する店舗が存在しないときの処理
                        <div className="flex justify-center col-span-2">
                            <p className="text-xl font-semibold text-center">該当する店舗はございません</p>
                        </div>
                    ))                   
                }
                </div>
            </div>     
        </div>
    )
}