/*
    ローディング状態の時に表示する画面
*/

export const Loading = () => {
    return(
        <>
            ローディング状態
        </>
    )
}