/*
    Loginビューの構成
    １・ログイン情報を入力するフォーム(メールアドレス、パスワード)がまとまったフィールド
*/
import { LoginField } from "../templates";

export const Login = () => {
    return(
        <div className="h-screen w-screen flex justify-center items-center">
            <LoginField />
        </div>
    )
}