/*
    スタンプ取得後、アニメーションなどを表示する
*/
import { getDownloadURL, ref } from "firebase/storage";
import { useState } from "react";
import { storage } from "../../../../firebase";

export const GetStamp = (props) => {
    const data = props.data;    //  獲得したスタンプのデータ

    const [imgURL, setImgURL] = useState("");   //  表示する画像のURL

    const firestoreLink = "gs://peanuts-store-info.appspot.com/Top/";

    //  画像URLの取得
    const gsReference = ref(storage, firestoreLink + data.img + "-top-300x300.jpg");
    getDownloadURL(gsReference)
        .then((url) => {
            setImgURL(url);
        });
    
    return(
        <div className="text-center font-bold px-4">
            <h1 className="text-2xl">スタンプGET!</h1>
            <h3 className="text-xl pt-4">{data.name}</h3>
            <div className="flex justify-center items-center py-8">
                <img src={imgURL} alt={data.name} className="w-3/4"></img>
            </div>
        </div>
    )
}