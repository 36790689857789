/*
    スタンプ取得後、アニメーションなどを表示する
*/
export const StampComplete = () => {
    
    return(
        <div className="text-center font-bold px-4">
            <h1 className="text-2xl">スタンプが貯まっています！</h1>
            <p className="text-xl py-4">スタンプカードボタンから応募をしてください！！</p>
        </div>
    )
}