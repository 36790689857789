import { LoginView } from "../views"

/*
    ユーザー登録しているユーザーがログインするためのページ
    役割
    1・ログイン管理
    2・ユーザー登録が完了していない場合、新規登録ページにリンクを飛ばす
*/
export const Login = () => {
    return(
        <>
            <LoginView />
        </>
    )
}