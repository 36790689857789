/*
    応募資格がなかった場合にHomeページに飛ばすコンポーネント
*/
import { useNavigate } from "react-router-dom";
import TopIcon from "../../../assets/top-img.png";

export const HomeLink = () => {
    const navigate = useNavigate();

    return(
        <div className="w-screen h-screen flex justify-center items-center">
            {/*内容*/}
            <div className="">
                <div className="font-bold border-2 border-gray-200 px-8 py-12">
                    <div className="flex justify-center items-center pb-4">
                        <img src={TopIcon} alt={"icon"} className="w-1/4 h-1/4"></img>
                    </div>
                    <h1 className="text-center text-xl py-4">応募することができません</h1>
                    <p className="text-left text-base py-8">下記のボタンをクリックしてください。</p>
                    <div className="flex justify-center items-center">
                        <button className="px-2 py-2 w-1/2 bg-cyan-400 text-white font-semibold rounded" onClick={() => navigate("/home")}>ホームに戻る</button>
                    </div>
                </div>
            </div>
        </div>
    )
}