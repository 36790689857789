/*
    スタンプをゲットするためのカメラを表示するモーダルウィンドウ（カメラを利用する）
*/
import { useState } from "react";
import { GetStamp } from "./QRComponent/GetStamp";
import { QRCodeReader } from "./QRComponent/QRCodeReader";
import { StampComplete } from "./QRComponent/StampComplete";

export const QRModal = (props) => {
    const closeModal = props.closeModal;    //  モーダルウィンドウを閉じる関数
    const stamp = props.stamp;              //  ユーザのスタンプデータ
    const storeData = props.storeData;      //  店舗情報
    const updateStamp = props.updateStamp;  //  スタンプデータを更新する

    /*モーダルウィンドウの表示内容を変更するためのboolean変数*/
    const [isGetStamp, setIsGetStamp] = useState(false);      //  スタンプを獲得した時にGetStampコンポーネントを表示する

    const [getStampData, setGetStampData] = useState();       //  ゲットしたスタンプの情報

    //  QRCodeReaderでスタンプを獲得した時の処理
    const getStampFunction = (data) => {
      //  取得したスタンプの店舗情報をstateに設定
      setGetStampData(data);
      //  スタンプ獲得画面に変更
      setIsGetStamp(true);
      //  アプリ側のスタンプデータを更新
      updateStamp();
    };
       
    return(
        <>
            <div
            className="justify-center items-center flex overflow-x-hidden  overscroll-y-contain fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="w-5/6 h-auto mx-auto max-w-3xl font-semibold animate-slideIn">
              {/*内容*/}
              <div className="relative py-4 px-1 rounded-lg shadow-lg relative flex flex-col w-full h-full bg-white outline-none focus:outline-none border-darkGreen border-8">
                {/*ボディ*/}
                <div className="">
                  {
                    ((stamp.length >= 4) ? (
                        <StampComplete />
                      ):(
                        isGetStamp ? (
                          //  スタンプ獲得時
                          <GetStamp data={getStampData}/>
                        ):(
                          //  スタンプ未獲得時
                          <QRCodeReader stamp={stamp} storeData={storeData} getStampFunction={getStampFunction}/>
                        )
                      )
                    )
                  }                    
                </div>
                {/*フッター*/}            
                <div className="flex justify-center items-center pt-2 h-1/5">
                    <button
                        className="h-full w-1/2 bg-emerald-500 text-white font-semibold uppercase text-sm px-6 rounded shadow py-4"
                        type="button"
                        onClick={() => closeModal()}
                    >
                        CLOSE
                    </button>
                </div>                
              </div>
            </div>
          </div>
          <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
        </>
    )
}