/*
    電話番号を入力するフォーム
*/

export const InputTel = (props) => {
    const onChangeText = props.onChangeText;

    return(
        <div>
            <input type="number" placeholder={"電話番号"} value={props.value} onChange={onChangeText} maxLength="13" className="bg-gray-200 appearance-none border-2 border-gray-400 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-cyan-300 focus:border-2"></input>
        </div>
    )
}