/*
    スタンプカードを表示するモーダルウィンドウ
*/

import { StampCard } from "./StampCardComponent/StampCard";
import { StampComplete } from "./StampCardComponent/StampComplete";

export const StampModal = (props) => {
    const stamp = props.stamp;      //  ユーザーのスタンプデータ
    const closeModal = props.closeModal;    //  モーダルウィンドウを閉じるときの処理

    const stampAchieved = 4;    //  スタンプカードの目標数


    return(
        <>
        <div
          className="justify-center items-center flex overflow-x-hidden  overscroll-y-contain fixed inset-0 z-50 outline-none focus:outline-none"
        >
          <div className="w-5/6 h-auto mx-auto max-w-3xl font-semibold animate-slideIn">
            {/*内容*/}
            <div className="relative py-4 px-1 rounded-lg shadow-lg relative flex flex-col w-full h-full bg-white outline-none focus:outline-none border-darkGreen border-8">
              {/*ボディ*/}
              <div className="">
                {
                    (stamp.length === stampAchieved)?(
                        <StampComplete stampAchieved={stampAchieved}/>
                    ):(
                        <StampCard stamp={stamp} stampAchieved={stampAchieved}/>
                    )
                }    
              </div>
              {/*フッター*/}            
              <div className="flex justify-center items-center pt-2 h-1/10">
                  <button
                      className="h-full w-1/2 bg-emerald-500 text-white font-semibold uppercase text-xl px-6 py-4 rounded shadow"
                      type="button"
                      onClick={() => closeModal()}
                  >
                      CLOSE
                  </button>
              </div>                
            </div>
          </div>
        </div>
        <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
      </>
    )
}