/*
    Signupビューの構成
    １・サインアップ    情報を入力するフォーム(メールアドレス、パスワード、確認用パスワード)がまとまったフィールド
*/
import { SignupField } from "../templates";

export const Signup = () => {
    return(
        <div className="h-screen w-screen flex justify-center items-center">
            <SignupField />
        </div>
    )
}