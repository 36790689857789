/*
  webアプリ内のルーティング設定
*/
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import { DefinitiveRegistration, Entry, Home, Login, Signup, Top } from "./components/pages";
import { ScrollTop } from "./components/templates";

function App() {
  return (
    <BrowserRouter>
      <ScrollTop />
      <Routes>        
        <Route path={'/'} element={<Top />} />
        <Route path={'/login/'} element={<Login />} />
        <Route path={'/signup/'} element={<Signup />} />
        <Route path={'/home/'} element={<Home />} />
        <Route path={'/register/'} element={<DefinitiveRegistration />} />
        <Route path={'/entry'} element={<Entry />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
