/*
    Topページに表示する紹介文をまとめたもの
*/
import minakuru from "../../../assets/minakuruA3.jpg"

export const Introduce = () => {
    return(
        <div id="introduce" className="w-screen bg-red-200 bg-opacity-80">
            {/*ヘッダー*/}
            <div id="top-introduce-header">
                <p className="text-center text-2xl text-white px-4 py-8 font-semibold bg-red-400">デジタルスタンプラリーとは？</p>
                <img src={minakuru} alt={"デジタルスタンプラリーとは？"}/>
            </div>
            {/*ボディ*/}
        </div>
    )
}