/*
    本登録を促すコンポーネント
*/
import { useNavigate } from "react-router-dom";
import { sendEmailVerification } from "firebase/auth";
import { auth } from "../../firebase";

export const DefinitiveRegistrationField = () => {
    const navigate = useNavigate(); //  画面遷移を行うインスタンス

    //  登録メールを再送する関数
    const sendRegisterMail = () => {
        sendEmailVerification(auth.currentUser)
            .then(() => {
                console.log("send Email!");
            })
    }

    return(
        <>
            {/*内容*/}
            <div className="border-2 border-gray-200 px-8 py-12">
                {/*見出し*/}
                <h1 className="text-center text-3xl font-bold pb-4">仮登録を受け付けました！</h1>
                <p className="text-center text-2xl font-bold text-red-500 py-4">まだ登録は完了しておりません。</p>
                {/*本文*/}
                <div className="py-4">
                    <p>ご登録いただいたメールアドレス宛に本人確認メールをお送りいたしました。</p>
                    <p>メール本文に記載されているURLをクリックして会員登録を完了させてください。</p>
                </div>
                {/*ログイン画面リンクボタン*/}
                <div className="py-4">
                    <p className="text-center py-2">本登録が完了したら下のログインボタンをクリック</p>
                    <div className="flex justify-center items-center py-2">
                        <button onClick={() => navigate("/login")} className="px-2 py-2 w-1/2 bg-cyan-400 text-white font-semibold rounded" >ログイン</button>
                    </div>
                    </div>
                {/*本登録メール再送信ボタン*/}
                <div>
                    <p className="text-center text-sm py-2">メールが届いてない場合は下のボタンをクリックしてください。</p>
                    <div className="flex justify-center items-center py-2">
                        <button onClick={sendRegisterMail} className="px-2 py-2 w-1/2 bg-red-400 text-white font-semibold rounded">登録メールを再送信</button>
                    </div>
                </div>                    
            </div>
        </>
    )
}