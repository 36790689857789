/*
    サインアウトに関するボタン
*/
import LogoutIcon from "../../../assets/homeicon/logout-icon.png";

export const SignoutButton = (props) => {
    const signout = props.signout;    //  サインアウトに関する関数

    return(
        <button onClick={signout} className="flex justify-center items-center text-white text-xs font-semibold border-l-2">
            <img src={LogoutIcon} alt={"logout-icon"} className="w-fit h-fit" />
        </button>
    )
}