/*
    QRコードを読み取るカメラを表示するコンポーネント
*/
import { QrReader } from "react-qr-reader";
import { useEffect, useState } from "react";
import { sha512 } from "js-sha512";
import { doc, updateDoc, getDoc, arrayUnion } from "firebase/firestore";
import { auth, db } from "../../../../firebase";
import { Loading } from "../../../../templates";

export const QRCodeReader = (props) => {
    const stamp = props.stamp;  //  ユーザのスタンプデータ
    const storeData = props.storeData;  //  店舗情報
    const getStampFunction = props.getStampFunction;  //  スタンプをゲットした後の処理

    const [errorMsg, setErrorMsg] = useState("");   //  エラーメッセージ
    const [position, setPosition] = useState({      //  ユーザの現在地
        lat: 0,
        long: 0
    });
    const [isLoading, setIsLoading] = useState(false);       //  データのやり取りをしている間、ローディング状態にする
    const [sendStamp, setSendStamp] = useState(stamp);  //  サーバに送信するスタンプデータ

    useEffect(() => {
        //  ユーザのスタンプ情報を取得
        getDoc(doc(db, "stamps", auth.currentUser.uid))
            .then((docsnap) => {
                setSendStamp(docsnap.data().stamp);       
            });
    },[])

    //  スタンプ獲得時の処理
    const getStamp = async(codes) => {
        await storeData.forEach((data, index) => {
            //  ハッシュ化した値と読み取った値を照合する
            if(sha512(data.id) === codes){
                //  サーバーに送信するスタンプデータの作成
                /*
                setSendStamp([...sendStamp,{
                    store: data.store
                }]);
                */
                //  バリデーションをクリアした時、更新したデータを送信する
                if(!searchStamp(data)){
                    updateDoc(doc(db, "stamps", auth.currentUser.uid), {
                        stamp: 
                        arrayUnion({
                            store: data.store,
                            id: data.id,
                            img: data.img
                        })
                    })
                    .then(() => {
                        //  スタンプ取得後の処理
                        getStampFunction(data);                            
                    })
                }else{
                    setErrorMsg("すでにスタンプを獲得しています");
                }
                
            }
        })
        //  ローディング状態を終了する
        setIsLoading(false);
    };

    //  今回取得するスタンプがすでに獲得しているかどうかを確認する
    const searchStamp = (data) => {
        let flag = false;   //  すでに獲得したスタンプかどうか

        //  スタンプのデータを確認
        stamp.forEach((item) => {
            if(item.id === data.id) flag = true
        });

        return flag;
    };

    //  現在地とスタンプ獲得店舗が指定した半径内に存在するかどうかを計算
    const searchDistance = (data) => {
        let flag = false;   //  半径内かどうか
        const maxDis = 10;  //  半径[km]（距離は自由に変更可能）

        //  モーダルウィンドウを開いたときに現在の位置情報を取得
        navigator.geolocation.getCurrentPosition((pos) => {
            setPosition({
                lat: pos.coords.latitude,
                long: pos.coords.longitude
            });
        });

        //  位置情報の計算
        const dis = distance(position.lat, position.long, data.location.latitude, data.location.longitude);
    
        //  disがmaxDis以下の場合、trueを返す
        dis < maxDis && (flag = true)

        return flag;
    };

    //  2地点間の距離を計算する関数(kmに換算)
    const distance = (lat1, lng1, lat2, lng2) => {
        const R = Math.PI / 180;
        lat1 *= R;
        lng1 *= R;
        lat2 *= R;
        lng2 *= R;
        return 6371 * Math.acos(Math.cos(lat1) * Math.cos(lat2) * Math.cos(lng2 - lng1) + Math.sin(lat1) * Math.sin(lat2));
    };

    const QRCameraValidation = (code) => {
        switch(code){
            case 'DOMException: Requested device not found':
                setErrorMsg("カメラが使用できません");
                break;
            default:
                break;
        }
    };

    const setStamp = async(data) => {
        setSendStamp([...sendStamp,{
            store: data.store
        }]);
    }

    return(
        <>
            <h1 className="text-xl text-center pt-8 px-4 font-bold">スマホをQRコードにかざしてください</h1>
            <p className="text-sm text-red-500 text-center py-4">{errorMsg}</p>
            <div className="py-4">
                <QrReader 
                    onResult={(result, error) => {
                        //  QRコード読み取り成功した場合、コードを格納
                        if(!!result){       
                            setIsLoading(true);     
                            //  スタンプをゲットする処理
                            getStamp(result?.text);
                        }
                        //  QRコード読み取り失敗した場合、コンソールにエラーを表示
                        if(!!error){
                            console.log("error:",error);
                            //  （エラー内容ごとのエラーメッセージを導入）
                            QRCameraValidation(error);
                        }
                    }}
                    style={{width:'100%'}}
                    constraints={{facingMode:'environment'}}
                />
                {isLoading && <Loading />}
            </div>
        </>
    )
}