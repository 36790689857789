/*
    店舗情報をモーダルウィンドウ形式で表示したもの
*/
import { useState, useEffect } from "react";
import CalendarIcon from "../../../../assets/storeicon/calendar.png";
import ClockIcon from "../../../../assets/storeicon/clock.png";
import TelephoneIcon from "../../../../assets/storeicon/tel.png";
import PositionIcon from "../../../../assets/storeicon/position.png";

export const StoreModal = (props) => {
    const data = props.data;    //  リストに表示するデータ
    const closeModal = props.closeModal;  //  モーダルウィンドウを閉じる関数
    const stampFlag = props.stampFlag;    //  スタンプをすでに獲得しているかどうか

    const [tel, setTel] = useState(""); //  電話番号のURL
    const [map, setMap] = useState(""); //  地図のURL

    useEffect(() => {
        //  電話番号URLの作成
        const str = data.tel.replace('-','');
        setTel("tel:",str);

        //  地図URLの作成
        setMap("https://maps.google.co.jp/maps?ll=" + data.location.latitude + "," + data.location.longitude + "&z=18&hl=ja");
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return(
      <>
          <div
            className="justify-center items-center flex overflow-x-hidden  overscroll-y-contain fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="w-5/6 h-3/4 mx-auto max-w-3xl font-semibold animate-slideIn">
              {/*内容*/}
              <div className="relative py-4 px-1 rounded-lg shadow-lg relative flex flex-col w-full h-full bg-white outline-none focus:outline-none border-darkGreen border-8">
                {/*ボディ*/}
                <div className="h-9/10 overflow-y-scroll">
                  {/*画像*/}
                  <div className="flex justify-center items-center">
                          <img src={props.img} alt={data.store} className="w-3/4"></img>                      
                  </div>
                  {/*店名*/}
                  <h1 className="text-2xl py-8 px-2 text-center font-bold">{data.store}</h1>
                  {/*Infomation*/}
                  <div className="relative flex-auto px-6 py-2">
                      <div className="grid grid-cols-1 gap-0 font-bold text-sm">
                            {/*住所*/}
                            <div className="w-full flex items-center border-t-2 p-2 h-iconlist">
                                <img src={PositionIcon} alt="住所"></img>
                                <a href={map}><p className="px-6">{data.address}</p></a>
                            </div>
                            {/*電話番号*/}
                            <div className="w-full flex items-center border-t-2 p-2">
                                <img src={TelephoneIcon} alt="電話番号"></img>
                                <a href={tel}><p className="text-blue-400 px-6">{data.tel}</p></a>
                            </div>
                            {/*営業時間*/}
                            <div className="w-full flex items-center border-t-2 p-2">
                                <img src={ClockIcon} alt="営業時間"></img>
                                <p className="px-6">{data.operatingHours}</p>
                            </div>
                            {/*定休日*/}
                            <div className="w-full flex items-center border-t-2 p-2">
                                <img src={CalendarIcon} alt="定休日"></img>
                                <p className="px-6">{data.holiday}</p>
                            </div>
                            {/*リンク*/}
                            <div className="w-full flex items-center border-t-2 p-2">

                            </div>
                      </div>
                  </div>
                </div>
                {/*フッター*/}            
                <div className="flex justify-center items-center pt-2 h-1/10">
                    <button
                        className="h-full w-1/2 bg-emerald-500 text-white font-semibold uppercase text-xl px-6 rounded shadow"
                        type="button"
                        onClick={() => closeModal()}
                    >
                        CLOSE
                    </button>
                </div>                
              </div>
            </div>
          </div>
          <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
        </>
    )
}