/*
    Top画面のフッター　Loginページに遷移する
*/

import { useNavigate } from "react-router-dom"

export const TopFooter = () => {
    const navigate = useNavigate(); //  画面遷移用のインスタンス
    
    return(
        <footer id="top-topfooter" className="flex justify-center items-center w-screen">
            <button className="fixed flex justify-center items-center bottom-4 w-5/6 h-homeFooter rounded-full p-4 bg-gradient-to-r from-cyan-500 to-blue-500"
                onClick={() => navigate("/login")}
                >
                <p className="text-center text-white text-xl font-semibold">ログイン</p>
            </button>
        </footer>
    )
}