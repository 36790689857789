/*
    スタンプが貯まった参加者に表示するコンポーネント
*/
import { Link } from "react-router-dom";
import minakuruIcon from "../../../../../assets/minakuru.jpg";

export const StampComplete = (props) => {
    const stampAchieved = props.stampAchieved;  //  スタンプカードの目標数

    return(
        <>
            <div className="font-bold px-4 pt-8">
                <h1 className="text-xl text-center">スタンプをコンプリートしました!</h1>
                <div className="py-4 text-base text-left">
                    <p>スタンプを{stampAchieved}個ゲットしました！</p>
                    <p>下のアイコンをクリックして、応募フォームに入力してください。</p>
                </div>
                <Link to={"/entry"} state={{stampAchieved: stampAchieved}}>
                    <div className="flex justify-center items-center py-4">
                        <img src={minakuruIcon} alt="stampIcon"></img>
                    </div>
                </Link>
            </div>
        </>
    )
}