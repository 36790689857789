/*
    TopViewの構成要素
    １・イメージビュー（スタンプラリーサイトの起動時に表示される画面）
    ２・スタンプラリーの参加方法・手順
    ３・スタンプラリー主催者が掲載したい情報・概要
    ※データベースからdate、introduce、articleの情報を取得する予定
*/

import { Introduce, Article, Main, TopFooter, Goods, Infomation } from "../templates";

const startDate = { //  スタンプラリーの開催日
    year: 2023,
    month: 6,
    date: 12
}

const endDate = {   //  スタンプラリーの終了日
    year: 2023,
    month: 6,
    date: 30
}

const article = [   //  スタンプラリー主催者が掲載したい情報・概要
    {   
        title: "みなくる商店会とは？",
        sentences: [
            "「みなくる商店会」はお客様にわかりやすい商店会、ニーズに沿った商店会を目指し、新たな事業展開して参ります。",
            "お客様が使いやすい身近で便利「まちの顔」として、加盟店一丸となって地域社会に貢献して参ります。"
        ]
    }
];


export const Top = () => {
    return(
        <>
            <div className="w-screen">
                <Main startDate={startDate} endDate={endDate}/>
                <Introduce />
                <Infomation />
                <div className="h-homeFooter-margin w-screen"></div>
                <TopFooter />
            </div>
        </>
    )
}