/*
    DefinitiveRegistrationビューの構成
    １・本登録用のフィールド
    ２・ログインページへのリンク
*/
import { DefinitiveRegistrationField } from "../templates";

export const DefinitiveRegistration = () => {
    return(
        <div className="h-screen w-screen flex justify-center items-center">
            <DefinitiveRegistrationField />
        </div>
    )
}