/*
    ログインに必要なメールアドレスとパスワードを入力するフォーム
*/
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useState } from "react";
import { InputMailaddress, InputPassword, SigninButton } from "../../parts"
import { auth } from "../../firebase";
import { Link, useNavigate } from "react-router-dom";
import { sendPasswordResetEmail } from "firebase/auth";
import TopIcon from "../../../assets/top-img.png";

export const LoginField = () => {
    //  入力フォームの変数
    const [mailaddress, setMailaddress] = useState(""); //  メールアドレス
    const [password, setPassword] = useState("");       //  パスワード

    const [isReissue, setIsReissue] = useState(false);  //  パスワード再発行するかどうか
    const [ReMailaddress, setRemailAddress] = useState(""); //  再発行用メールアドレス
    
    const navigate = useNavigate();     //  別リンクへ飛ばすためのnavigateインスタンス

    //  エラーに関するオブジェクト
    const [error, setError] = useState({
        isError: false,     //  入力された内容にエラーがあるかどうか
        errorMsg: ""        //  エラー内容（表示させるエラーの文章）
    });

    //  本登録しているかどうか
    const [isRegister, setIsRegister] = useState(false);

    //  入力フォームの値をmailaddressに格納
    const onMailaddressChange = (e) => {
        setMailaddress(e.target.value);
    };

    //  入力フォームの値をpasswordに格納
    const onPasswordChange = (e) => {
        setPassword(e.target.value);
    };

    //  入力フォームの値をReMailaddressに格納
    const onReMailAddressChange = (e) => {
        setRemailAddress(e.target.value);
    }

    //  バリデーション機能
    const validation = (errorCode) => {
        var errorMsg = "";  //  エラーメッセージ

        //  記入漏れ
        if(mailaddress === "" || password === ""){
            errorMsg = "メールアドレスまたはパスワードが入力されていません"
            showErrorMsg(errorMsg);
            return;
        }

        //  firebase authenticationを利用した認証       
        switch(errorCode){
            case 'auth/invalid-email':
                errorMsg = "メールアドレスの形式が正しくありません";
                break;
            case 'auth/user-not-found':
                errorMsg = "アカウントが存在しません";
                break;
            case 'auth/user-mismatch':
                errorMsg = "メールアドレスまたはパスワードが違います";
                break;
            case 'auth/wrong-password':
                errorMsg = "メールアドレスまたはパスワードが違います";
                break;
            default:
                errorMsg = 'エラーが発生しました。しばらく時間をおいてお試しください';
        }
        showErrorMsg(errorMsg);
    }

    //  エラーメッセージを表示する関数
    const showErrorMsg = (errorMsg) => {
        //  エラーメッセージを格納
        setError({
            isError: true,
            errorMsg: errorMsg
        })
    }

    //  サインインのときに実行される関数
    const login = () => {
        // firebase Authenticationにログイン
        signInWithEmailAndPassword(auth, mailaddress, password)
            .then(() => {
                //  パスワード認証に成功した時、本登録が完了していた場合ホーム画面に遷移
                if(auth.currentUser.emailVerified){
                    //  本登録が完了している場合
                    console.log("signin complete!");
                    navigate("/home");
                }else{
                    //  本登録が完了していない場合
                    setIsRegister(!isRegister);
                }
                
            })
            .catch((error) => {
                //  パスワード認証に失敗した時、バリデーションをしてエラーを表示
                console.log("error Discover!");
                validation(error.code);
            })
    };

    const sendRessetingMail = async() => {
        const auth = getAuth();
        await sendPasswordResetEmail(auth, ReMailaddress)
            .then((resp) => {
                console.log("success")
            })
            .catch((error) => {
                console.log(error)
            })
    }

    return(
        <div id="login-loginfield" className="relative border-2 border-gray-200 px-8 py-12">
            {/*ヘッダー*/}
            <div id="login-loginfield-header" className="py-2">
                <div className="flex justify-center items-center pb-4">
                    <img src={TopIcon} alt={"icon"} className="w-1/4 h-1/4"></img>
                </div>
                <h1 className="text-3xl font-bold text-center">ログイン</h1>
            </div>
            {/*エラーメッセージ*/}
            <div id="login-loginfield-error" className="py-4">
            {
                (error.isError && 
                    <p className="text-sm text-red-500 px-2">{error.errorMsg}</p>
                )               
            }
            {
                (isRegister &&
                    <p  className="text-sm px-2" onClick={() => navigate("/register")}>本登録が完了していません。こちらから本登録を完了させてください。</p>
                )
            }
            </div>
            {/*メールアドレス入力フォーム*/}
            <div id="login-loginfield-mailaddress" className="py-2">
                <p className="text-left text-sm font-bold">メールアドレス</p>
                <InputMailaddress value={mailaddress} onChangeText={onMailaddressChange} />
            </div>
            {/*パスワード入力フォーム*/}
            <div id="login-loginfield-password" className="py-2">
                <p className="text-left text-sm font-bold">パスワード</p>
                <InputPassword onChangeText={onPasswordChange} />
            </div>
            {/*ログインボタン*/}
            <div id="login-loginfield-loginbutton" className="py-4 flex justify-center items-center">
                <SigninButton signin={login}/>
            </div>
            {/*リンクフォーム*/}
            <div id="login-loginfield-linkform" className="pt-2">
                <p className="text-sm text-center">アカウントをお持ちでない方はこちらから</p>
                <Link to={"/signup"}>
                    <p className="text-sm text-cyan-300 underline text-center">新規登録</p>
                </Link>
            </div>
            {/*パスワード再設定*/}
            <div>
                <p className="text-sm text-cyan-300 underline text-center cursor-pointer py-2" onClick={() => setIsReissue(true)}>パスワードを忘れた方はここをタップ</p>
                {
                    (isReissue) && (
                        <div className="text-center py-2">
                            <p className="py-2">メールアドレス宛に再設定メールを送信します</p>
                            <input type="text" onChange={onReMailAddressChange} value={ReMailaddress} className="bg-gray-200 appearance-none border-2 border-gray-400 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-cyan-300 focus:border-2"></input>
                            <div className="py-4">
                                <button className="px-2 py-2 w-1/2 bg-cyan-400 text-white font-semibold rounded" onClick={sendRessetingMail}>送信</button>
                            </div>
                        </div>
                    )
                }
            </div>
            {/*Topページリンク*/}
            <Link to={"/"}>
                <p className="absolute top-2 left-2">トップページへ戻る</p>
            </Link>
        </div>
    )
}