/*
    Entryビューの構成
    １・エントリーフォームの入力
        -> 入力用鋼の確認
        -> ホーム画面へのリンク
    ２・以下の条件に当てはまらない場合、ホームに飛ばすコンポーネントを表示する
        １・まだ応募フォームに応募していない
        ２・スタンプの数が目標の値に達していない
*/
import { useEffect, useState } from "react";
import { collection, doc, getDoc, setDoc, addDoc } from "firebase/firestore";
import { auth, db, entryDB } from "../firebase";
import { HomeLink, EntryForm, ConfirmEntryForm, CompleteEntryForm } from "../templates";
import { useAuthState } from "react-firebase-hooks/auth";
import axios from "axios";

export const Entry = () => {
    //  応募条件をクリアしているかどうか
    const [flag, setFlag] = useState(false);
    //  応募確認画面を表示するかどうか
    const [confirm, setConfirm] = useState(false);
    //  応募完了画面を表示するかどうか
    const [complete, setComplete] = useState(false);
    

    //  入力フォームの変数
    const [lastName, setLastName] = useState("");       //  姓
    const [firstName, setFirstName] = useState("");     //  名
    const [mailaddress, setMailaddress] = useState(""); //  メールアドレス
    const [tel, setTel] = useState("");                 //  電話番号
    const [postCode, setPostCode] = useState("");       //  郵便番号             
    const [address, setAddress] = useState("");         //  住所
    const [gender, setGender] = useState("");           //  性別

    const [formError, setFormError] = useState({        //  エラー
        isError: false,
        errorMsg: ""
    })

    const url = "https://zipcloud.ibsnet.co.jp/api/search?zipcode=";    //  住所APIのURL
    const stampAchieved = 5;    //  スタンプカードの目標数

    const genderArray = ["男性","女性","その他","無回答"];  //  性別のリスト

    const [user, loading] = useAuthState(auth);

    useEffect(() => {
        if(user){
            getDoc(doc(db, "stamps", user.uid))
            .then((doc) => {
                if(doc.data().stamp.length <= stampAchieved){
                    //  エントリーフォームを表示
                    setFlag(true);
                    setGender(genderArray[0]);
                }
            })
        }
    },[user]);

    //  入力フォームの値をlastNameに格納
    const onLastNameChange = (e) => {
        setLastName(e.target.value);
    }

    //  入力フォームの値をfirstNameに格納
    const onFirstNameChange = (e) => {
        setFirstName(e.target.value);
    }

    //  入力フォームの値をtelに格納
    const onTelChange = (e) => {
        //  入力された数値が数字以外の場合、入力を受け付けないようにする
        setTel(isNaN(e.target.value) ? postCode : e.target.value)    
    }

    //  入力フォームの値をmailaddressに格納
    const onMailaddressChange = (e) => {
        setMailaddress(e.target.value);
    }

    //  入力フォームの値をpostCode.mainに格納
    const onPostCodeChange = (e) => {
        console.log(e.target.value);
        //  入力された数値が数字以外の場合、入力を受け付けないようにする
        setPostCode(isNaN(e.target.value) ? postCode : e.target.value)        
    }

    //  入力フォームの値をaddressに格納
    const onAddressChange = (e) => {
        setAddress(e.target.value);
    }

    //  入力フォームの値をgenderに格納
    const onGenderChange = (e) => {
        setGender(e.target.value);
    }

    //  住所検索
    const searchAddress = () => {
        //  郵便番号から住所を検索する
        axios
            .get(url + postCode)
            .then((res) => {
                const getData = res.data.results[0];
                console.log(getData);
                //  データを取得するとaddressにデータを格納する
                setAddress(getData.address1 + getData.address2 + getData.address3);
            });
    }

    //  確認ボタンをクリックしたときの処理
    const onClickConfirmButton = () => {
        //  すべての項目が入力されていた場合、確認画面に変更
        if(firstName === "" || lastName === "" || mailaddress === "" || tel === "" ||
            postCode === "" || address === ""){
                setFormError({
                    isError: true,
                    errorMsg: "入力されていない項目があります"
                })
            }else{
                setConfirm(true);
            }  
    }

    //  応募ボタンをクリックした時の処理
    const onClickEntryButton = async() => {
        //  firebaseにデータを送信する
        await addDoc(collection(entryDB, "entry"),{
            name: lastName + " " + firstName,
            mailaddress: mailaddress,
            tel: tel,
            postCode: postCode,
            address: address
        })

        //  stampsの中身を消去
        await setDoc(doc(db, "stamps", auth.currentUser.uid),{
            stamp: []
        })
        .then(() => {
            //  エントリー完了画面にジャンプする
            setComplete(true);            
        })
    }

    return(
        <>            
            {
                loading ? (
                    <></>
                ):(
                    flag ? (
                        complete?(
                            <CompleteEntryForm />
                        ):(
                            confirm ? (
                                <ConfirmEntryForm 
                                lastName={lastName}
                                firstName={firstName}
                                mailaddress={mailaddress}
                                tel={tel}
                                postCode={postCode}
                                address={address}
                                onClickReturn={() => setConfirm(false)}
                                onClickEntry={onClickEntryButton}
                                />
                            ):(
                                <EntryForm 
                                onLastNameChange={onLastNameChange}
                                onFirstNameChange={onFirstNameChange}
                                onTelChange={onTelChange}
                                onMailaddressChange={onMailaddressChange}
                                onPostCodeChange={onPostCodeChange}
                                onAddressChange={onAddressChange}
                                onGenderChange={onGenderChange}
                                searchAddress={searchAddress}
                                firstName={firstName}
                                lastName={lastName}
                                mailaddress={mailaddress}
                                tel={tel}
                                postCode={postCode}
                                address={address}
                                gender={gender}
                                onClickButton={onClickConfirmButton}
                                error={formError}
                                genderArray={genderArray}
                                />
                            )
                        )                       
                    ):(
                        <HomeLink />
                    )
                )
                
            }
        </>
    )
}